
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import { HasDesktopPart } from "@/mixins/properties/has-desktop-part";

  @Component
  export default class PagePropertyPartDesktopSelect extends Mixins(HasDesktopPart) {
    @Prop({ default: null }) value!: string | null;
    @Prop({ default: false }) clearable!: boolean;
    @Prop({ default: false }) taggable!: boolean;

    @Prop({ default: () => [] }) options!: Array<{ label: string; value: string }>;
  }
